import { HStack, VStack } from '@domir/react-stacks';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { Footer } from '@thingos/thingos-homepage-shared';

import Container from '../components/Container';
import { Page } from '../components/Page';
import { SideBySide } from '../components/SideBySide';
// import { SocialLinks } from '../components/SocialLink';
import { Body, HeaderH3 } from '../components/Typography';
import { IndexLayout } from '../layouts';

const IndexPage = () => {
	const { t } = useTranslation('contact');

	return (
		<IndexLayout>
			<Page>
				<Container>
					<SideBySide reverseStackOrder spacing={16}>
						<VStack>
							<HeaderH3>{t('contact.title')}</HeaderH3>
							<HStack>
								<Footer.SocialLink url="mailto:info@thingos.io" icon="services/email" />
								<Footer.SocialLink
									url="https://www.linkedin.com/showcase/meshlighting"
									icon="services/linkedIn"
								/>
								<Footer.SocialLink
									url="https://www.facebook.com/meshlighting"
									icon="services/facebook"
								/>
								<Footer.SocialLink url="https://twitter.com/thingosio" icon="services/twitter" />
								<Footer.SocialLink
									url="https://www.youtube.com/channel/UC-FZLhhRwlXvJEcWdZ8-uoQ"
									icon="services/youtube"
								/>
							</HStack>
							<HeaderH3>{t('contact.contact.title')}</HeaderH3>
							<Body>
								<div>ThingOS GmbH & Co. KG</div>
								<div>Naststr. 15b</div>
								<div>70184 Stuttgart</div>
								<div>
									{t('contact.contact.mail')} <a href="mailto:info@thingos.io">info@thingos.io</a>
								</div>
								<div>
									{t('contact.contact.phone')} <a href="tel:+4971121952500">+49 711 21952 500</a>
								</div>
							</Body>

							<HeaderH3>{t('contact.directors.title')}</HeaderH3>
							<Body>Sibylle Thierer, Jörg Schmid, Thomas Kubitza</Body>

							<HeaderH3>{t('contact.address.title')}</HeaderH3>
							<Body>ThingOS GmbH & Co. KG – ARENA2036 Pfaffenwaldring 19 70569 Stuttgart</Body>
						</VStack>
						<VStack spacing={16}>
							<StaticImage
								src="../content/location-arena2036-outside.jpeg"
								alt="Arena2036"
								style={{ flex: 1 }}
								objectFit="cover"
							/>
							<StaticImage
								src="../content/location-arena-map.png"
								alt="Arena2036"
								style={{ flex: 1 }}
								objectFit="cover"
							/>
						</VStack>
					</SideBySide>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default IndexPage;
